import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { useNavigate } from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Paper, ThemeProvider, LinearProgress } from '@mui/material';
import Header from '../components/combinationSearchComponents/Header';
import getTheme from '../components/combinationSearchComponents/configuration/Themes';
import SearchAndFilter from '../components/combinationSearchComponents/SearchAndFilter';
import { fetchAllCourses, fetchContentOfCourse, resetSelectedVideo, resetContentOfCourse, resetCourseMovieData, setSelectedVideoDetails } from '../features/coursesSlice';
import { resetCombinationData, resetQuestionsArchive } from '../features/combinationSearchSlice';
import { resetSolverData, resetQuestionsSolverArchive } from '../features/solverSearchSlice';
import { getCourseContent } from '../api/serverApis/coursesApi';
import { fetchBlob, selectUrlByBlob } from '../features/blobFilesSlice';

const CourseSelectedPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const courses = useSelector((state: RootState) => state.courses.courses);
  //const contentOfCourse = useSelector((state: RootState) => state.courses.contentOfCourse);
  const [searchTerm, setSearchTerm] = useState("");
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light');
  const [isLoading, setIsLoading] = useState(false); // Track loading state  
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);


  const toggleTheme = () => {
    setThemeMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    dispatch(resetSelectedVideo());
    dispatch(fetchAllCourses());
    dispatch(resetContentOfCourse());
    dispatch(resetCourseMovieData());
    dispatch(resetQuestionsArchive());
    dispatch(resetCombinationData());
    dispatch(resetSolverData());
    dispatch(resetQuestionsSolverArchive());
  }, [dispatch]);

  const handleCourseSelect = async (courseId: string) => {
    // Set loading state to true before starting to fetch content
    setIsLoading(true);

    try {
      // Await fetching the content for the selected course
      const contentOfCourse: any = await getCourseContent(courseId);
      if (contentOfCourse) {
        const urls = contentOfCourse.data.map((item: any) => item.url);
        for (let url in urls) {
          getTheLocalFilesFromServer(urls[url]);
        }
      }

      // Once the content is fetched, select the first video
      const firstVideo = contentOfCourse && contentOfCourse.data[0];
      if (firstVideo) {
        const firstSubVideo = firstVideo.content && firstVideo.content[0];

        // Dispatch to set selected video details       
        dispatch(setSelectedVideoDetails({
          movieId: firstVideo.movieId,
          id: firstSubVideo?.id || "",
          courseId: courseId,
        }));


        // Navigate to the course page with the first video selected
        navigate(`/course`);
      }
    } catch (error) {
      console.error("Failed to fetch course content", error);
    } finally {
      // Set loading state to false once data is loaded or error occurs
      setIsLoading(false);
    }
  };

  const getTheLocalFilesFromServer = (thisUrl: string) => {
    const type = determineContentType(thisUrl);
    //if (type === 'mp4' || type === 'imbedPdf') {
    if (type === 'imbedPdf') {
      dispatch(fetchBlob(thisUrl));
    }
  }
  const determineContentType = (url: string) => {
    if (!url) return 'website';
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return 'youtube';
    } else if (url.includes('.mp3')) {
      return 'mp3';
    } else if (url.includes('.pdf')) {
      return 'imbedPdf';
    } else if (url.includes('.mp4')) {
      return 'mp4';
    } else {
      return 'website';
    }
  };


  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);

  return (
    <ThemeProvider theme={theme}>
      <Box position='fixed' top={0} left={0} width={'100%'} zIndex={1200}>
        <Header header={"Xarcam Courses"} themeMode={themeMode} toggleTheme={toggleTheme} />
      </Box>
      <Box sx={{ marginTop: '7rem', marginBottom: '15rem' }}>
        {/* Show progress bar while loading */}
        {isLoading && <LinearProgress sx={{ width: '100%' }} />}
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <Grid container spacing={2} >
            {courses && courses
              .filter(course => course.courseName.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((course) => (
                <Grid item xs={6} sm={4} md={3} key={course.courseId} sx={{ cursor: 'pointer' }}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      ':hover': {
                        transform: 'scale(1.05)',
                        transition: 'transform 0.2s'
                      }
                    }}
                    onClick={() => handleCourseSelect(course.courseId)}
                  >
                    <CardContent sx={{ paddingBottom: '0.5rem' }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          direction: isHebrew(course.courseName) ? 'rtl' : 'ltr',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                        }}
                      >
                        {course.courseName}
                      </Typography>
                    </CardContent>

                    <CardMedia
                      component="img"
                      height="250rem"
                      image={course.courseId === "hrPlus_demo"? "https://media.licdn.com/dms/image/v2/D4D0BAQG837WQLuW4mw/company-logo_200_200/company-logo_200_200/0/1712483344516/hrpluseducation_logo?e=2147483647&v=beta&t=GgLgfYWORLD80pmR0gCAbRUdC5rFVZ9ZMqcYrwcWl_w":course.courseImage}
                      alt={course.courseName}
                      sx={{
                        borderRadius: '0.5rem',
                        margin: '0.5rem',
                      }}
                    />


                  </Card>
                </Grid>
              ))}
          </Grid>
          <Paper sx={{ height: '2rem', position: 'fixed', bottom: 0, left: 0, right: 0, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SearchAndFilter onSearch={setSearchTerm} />
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default CourseSelectedPage;
