import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Button, CircularProgress, Container, Typography, Alert, Paper } from '@mui/material';
import * as yup from 'yup';
import { ForgotPassword } from '../../features/userSlice';
import { RootState } from '../../store';

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export const ForgotPasswordForm: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.user);

  // New state to track if form was successfully submitted
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      await dispatch(ForgotPassword(values.email)).unwrap();  // Ensure we handle the async call properly
      setIsSubmitted(true);  // Set the form as submitted
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Paper elevation={3} style={{ padding: '2em', width: '100%' }}>
          <Typography variant="h4" align="center">Forgot Password</Typography>
          <Typography variant="subtitle2" align="center" style={{ marginBottom: '2em' }}>
            Enter your email to receive a password reset link.
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}

          {/* Show success message only if form was submitted and status is idle */}
          {isSubmitted && status === 'idle' && (
            <Alert severity="success">Reset link sent to your email!</Alert>
          )}

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  margin="normal"
                />
                <Box display="flex" justifyContent="center" mt={2}>
                  {status === 'loading' ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
                      Send Reset Link
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>
    </Container>
  );
};
