import { openDB } from 'idb';

const DB_NAME = 'fileCache';
const DB_VERSION = 1;
const STORE_NAME = 'files';

export const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
};

export const saveFileToCache = async (url: string, blob: Blob) => {
  const db = await initDB();
  await db.put(STORE_NAME, blob, url);
};

export const getFileFromCache = async (url: string) => {
  const db = await initDB();
  return db.get(STORE_NAME, url);
};

export const clearCache = async () => {
  const db = await initDB();
  await db.clear(STORE_NAME);
};