import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Switch, FormControlLabel, Menu, MenuItem, Tooltip, IconButton } from '@mui/material';
import logo from '../../images/logo1.png'; // Adjust the path as necessary
import { getUserName } from '../../api/serverApis/userApi';
import { logout, getUserNameSlice } from '../../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import LogoutIcon from '@mui/icons-material/Logout';
import AvatarMenuComponent from '../avatarMenuComponents/avatarMenuComponent';
interface HeaderProps {
  themeMode: 'light' | 'dark';
  toggleTheme: () => void; // Function to toggle the theme
  header?: string,
  title?: string
}

const Header: React.FC<HeaderProps> = ({ themeMode, toggleTheme, header, title }) => {
  const handleLogoClick = () => {
    window.location.href = '/home'; // Redirect to the home page when the logo is clicked
  };
  //const [userName, setUserName] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const userName = useSelector((state: RootState) => state.user.userName);
  useEffect(() => {
    dispatch(getUserNameSlice());
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleHome = () => {
    handleMenuClose();
    window.location.href = '/home'; // Redirect to home
  };
  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle logout
  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();
    window.location.href = '/login'; // Redirect to login after logout
  };

  return (
    <AppBar
      position="static"
      sx={{
        height: '3rem',
        justifyContent: 'center', // Centers content vertically
        alignItems: 'center',
        overflow: 'hidden',
        // Different background for light and dark mode
        backgroundColor: themeMode === 'dark' ? '#424242' : '#F0F8FF',

      }}
    >
      <Toolbar sx={{
        minHeight: '3rem !important', // Ensures the Toolbar respects the AppBar height, overriding default styles
        alignItems: 'center', // Ensure the content inside the Toolbar is centered vertically
        justifyContent: 'space-between', // Spaces out logo and switch
        width: '100%', // Ensures the Toolbar spans the entire width of the AppBar
      }}>
        <Box display="flex" alignItems="center" height="100%"  >
          <Box onClick={handleHome} sx={{ cursor: 'pointer' }}>
            <img src={logo} alt="Logo" style={{ marginTop: '0rem', marginRight: 10, width: "100px", height: 'auto' }} />
            {/* <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ mt: '35px' }}
            >
              <MenuItem onClick={handleHome}>Home</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu> */}
          </Box>
          {userName && (
            <Typography
              fontSize="1rem"
              noWrap
              sx={{
                marginLeft: 2, // Add some spacing between the title and user name
                color: themeMode === 'dark' ? '#ffffff' : '#000000', // Adjust the color based on theme
              }}
            >
              {`Hello, ${userName}`}
            </Typography>
          )}
          <Typography
            fontSize="1rem"
            noWrap
            sx={{
              padding: '10px', color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          > |
          </Typography>
          <Typography
            fontSize="1rem"
            noWrap
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          >
            {header}
          </Typography>

          {title && <Typography
            fontSize="1rem"
            noWrap
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
          >{title}</Typography>}

        </Box>
        <Box>
          <FormControlLabel
            sx={{
              color: themeMode === 'dark' ? '#ffffff' : '#000000', // Lighter in dark mode, darker in light mode
            }}
            control={<Switch checked={themeMode === 'dark'} onChange={toggleTheme} />}
            label={themeMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
          />
          <AvatarMenuComponent userName={userName} />
        </Box>

      </Toolbar>
    </AppBar >
  );
};

export default Header;
