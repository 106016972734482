import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, Typography, Chip, SelectChangeEvent } from '@mui/material';
import ManagerDiagram from '../components/managerComponents/ManagerDiagram';
import ManagerTables from '../components/managerComponents/ManagerTables';
import ManagerForms from '../components/managerComponents/ManagerForms';
import RoleWarper from '../components/middlewareComponents/RoleWarper';
import { hasAccess } from '../components/middlewareComponents/AuthPolicy';
import AvatarMenuComponent from '../components/avatarMenuComponents/avatarMenuComponent';
type ComponentKeys = 'ManagerDiagram' | 'ManagerTables' | 'ManagerForms';
interface ComponentInfo {
  component: JSX.Element;
  displayName: string;
}
const ManagerPage: React.FC = () => {
  const [selectedComponents, setSelectedComponents] = useState<ComponentKeys[]>([]);


  // Wrap each component with RoleWarper and pass necessary props
  const components: Record<ComponentKeys, ComponentInfo> = {
    'ManagerDiagram': {
      component: <RoleWarper pageName="ManagerPage" componentName="ManagerDiagram" ComponentRead={<ManagerDiagram />} ComponentWrite={<ManagerDiagram />} />,
      displayName: 'Manager Diagram'
    },
    'ManagerTables': {
      component: <RoleWarper pageName="ManagerPage" componentName="ManagerTables" ComponentRead={<ManagerTables />} ComponentWrite={<ManagerTables />} />,
      displayName: 'Manager Tables'
    },
    'ManagerForms': {
      component: <RoleWarper pageName="ManagerPage" componentName="ManagerForms" ComponentRead={<ManagerForms />} ComponentWrite={<ManagerForms />} />,
      displayName: 'Manager Forms'
    },
  };
  const accessibleComponentKeys = Object.keys(components).filter(componentName =>
    hasAccess('ManagerPage', componentName as ComponentKeys)
  ) as ComponentKeys[];

  const handleChange = (event: SelectChangeEvent<ComponentKeys[]>) => {
    const { target: { value }, } = event;
    setSelectedComponents(
      typeof value === 'string' ? value.split(',') as ComponentKeys[] : value,
    );
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 3 }}>

        <Typography variant="h4" gutterBottom sx={{ marginLeft: 2 }}>
          Manager Page
        </Typography>
      </Box>


      <Box sx={{ minWidth: 120, marginBottom: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Choose Your Actions</InputLabel>
          <Select
            labelId="entity-type-multi-select-label"
            id="entity-type-multi-select"
            multiple
            value={selectedComponents}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Choose Your Actions" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(value => <Chip key={value} label={components[value].displayName} />)}
              </Box>
            )}
          >
            {accessibleComponentKeys.map(name => (
              <MenuItem key={name} value={name}>
                {components[name].displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div style={{ display: 'flex', height: '100vh' }}>
        {selectedComponents.map((componentName, index) => (
          <div key={index} style={{ flex: 1, overflow: 'auto' }}>
            {React.isValidElement(components[componentName].component) ? components[componentName].component : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManagerPage;