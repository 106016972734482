import React, { useState, useEffect, useRef } from 'react';
import VideoSemantic from '../VideoSemantic';
import VideoAnswer from '../VideoAnswer';
import VideoIndex from '../VideoIndex';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchSearchDeleteMovie } from '../../../features/combinationSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store';
import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Index, Semantic } from '../configuration/Interface';
import DynamicTable from '../../generalComponents/DynamicTable';
import VideoText from '../VideoText';
import LoadingDots from './LoadingDots';
interface MainSearchProps {
  data: any;
  theme: any;
  resultOfOpeningAskQuestion: any;
  handleMainSearchFullScreen: any;
  currentView: string;
}

const MainSearch: React.FC<MainSearchProps> = ({ handleMainSearchFullScreen, currentView, data, theme, resultOfOpeningAskQuestion }) => {
  const [openIndexes, setOpenIndexes] = useState<string[]>([]);
  const [openSemantics, setOpenSemantics] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string | false>(data && data.answerData && data.answerData.length > 0 ? `panel0` : false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<string | null>(null);
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({}); // Track loading state for each item
  const [fullScreenItem, setFullScreenItem] = useState<any>(null);
  const lightColors = ['white', '#F0F8FF']; // Softer shades for light theme
  const darkColors = ['black', '#353935']; // Deeper shades for dark theme
  const colors = theme.palette.mode === 'dark' ? darkColors : lightColors;
  const dispatch = useDispatch<AppDispatch>();
  const accordionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [prevAnswerDataLength, setPrevAnswerDataLength] = useState<number>(data?.answerData?.length || 0);

  const scrollToAccordion = (panel: string) => {
    setTimeout(() => {
      const accordionElement = accordionRefs.current[panel];
      if (accordionElement) {
        // Scroll the accordion into view first
        accordionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // After the initial scroll completes, adjust the scroll position slightly
        // setTimeout(() => {
        //   window.scrollBy(0, -150); // Adjust this value (e.g., -50) to scroll higher or lower after the initial scroll
        // }, 500); // Ensure this delay gives enough time for the 'scrollIntoView' to complete
      }
    }, 100); // Initial timeout before triggering scroll
  };
  const scrollToOriginalPosition = (currentScrollPosition: any) => {
    setTimeout(() => {
      window.scrollTo({
        top: currentScrollPosition, // Preserve the initial scroll position
        behavior: 'smooth',
      });
    }, 100);
  }
  const scrollToFirstPanel = () => {
    const firstPanelElement = accordionRefs.current['panel0'];
    if (firstPanelElement) {
      firstPanelElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Handle when new answerData is added
  useEffect(() => {
    const currentAnswerDataLength = data?.answerData?.length || 0;

    if (currentAnswerDataLength > prevAnswerDataLength) {
      // Close all accordions
      setExpanded(false);
      setLoadingStates({});
      // Scroll to top
      scrollToTopOfPage();

      // Open the first accordion after a small delay (to ensure smooth scroll first)
      setTimeout(() => {
        setExpanded(`panel0`);
        scrollToAccordion('panel0');
      }, 500);
    }

    // Update the previous length with the current length
    setPrevAnswerDataLength(currentAnswerDataLength);
  }, [data?.answerData, prevAnswerDataLength]);

  // Utility function to check if an element is in the viewport
  const isElementInViewport = (element: HTMLElement | null) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    const viewHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    return (
      rect.top >= 0 && rect.left >= 0 && rect.bottom <= viewHeight && rect.right <= viewWidth
    );
  };


  const handleToggleIndex = (articleId: string) => {
    setOpenIndexes(prev => prev.includes(articleId) ? prev.filter(id => id !== articleId) : [...prev, articleId]);
  };

  const handleToggleSemantic = (articleId: string) => {
    setOpenSemantics(prev => prev.includes(articleId) ? prev.filter(id => id !== articleId) : [...prev, articleId]);
  };
  const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const handleChange = (panel: string, item: any) => async (event: React.SyntheticEvent, isExpanded: boolean) => {
    const currentScrollPosition = window.scrollY;

    if (isExpanded && item.answer.answer === "Waiting for an answer...") {
      scrollToFirstPanel();
      setLoadingStates(prev => ({ [panel]: true })); // Set loading to true for the expanded panel
      await openingAskQuestion(item.searchId, item.answerDataId);
      scrollToFirstPanel();
      //setLoadingStates(prev => ({ ...prev, [panel]: false }));
    }
    setExpanded(isExpanded ? panel : false);
    setTimeout(async () => {
      if (isExpanded) {
        setLoadingStates(prev => ({ [panel]: true })); // Set loading to true for the expanded panel
        await openingAskQuestion(item.searchId, item.answerDataId);
        // Scroll to the top of the newly expanded accordion
        scrollToOriginalPosition(currentScrollPosition);
        scrollToAccordion(panel);

      }
    }, 100);
  };
  const handleChangeInFullScreen = async (panel: string, item: any) => {
    await openingAskQuestion(item.searchId, item.answerDataId);
    setLoadingStates(prev => ({ [panel]: true }));
    setFullScreenItem(item.answerDataId);
  }
  const handleMovieDelete = () => {
    dispatch(fetchSearchDeleteMovie({ courseId: data.courseId, searchId: data.searchId, answerDataId: deleteConfirmOpen }));
    setDeleteConfirmOpen(null);
  };

  const sortedAnswerData = data && data.answerData ? [...data.answerData].sort((a: any, b: any) => new Date(b.answer.date).getTime() - new Date(a.answer.date).getTime()) : [];

  useEffect(() => {
    if (sortedAnswerData.length > 0) {
      const firstNonEmptySemanticPanel = sortedAnswerData.findIndex((item: any) =>
        item &&
        item.movies &&
        Array.isArray(item.movies) &&
        item.movies.some((movie: any) => movie?.semantic && movie?.semantic.length > 0)
      );
      const firstAnswerIfEmpty = sortedAnswerData.findIndex((item: any) => item?.answer && item.answer.answer);
      if (firstNonEmptySemanticPanel !== -1) {
        setLoadingStates(prev => ({ ['panel1']: false }));
        setExpanded(`panel${firstNonEmptySemanticPanel}`);
      }
      else if (firstAnswerIfEmpty !== -1) {
        setLoadingStates(prev => ({ ['panel1']: false }));
        setExpanded(`panel${firstAnswerIfEmpty}`);
      }
      else {
        setExpanded(false);
      }
    }
  }, [data.answerData]);


  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);

  const openingAskQuestion = async (searchId: string, answerDataId: string) => {
    await resultOfOpeningAskQuestion(searchId, answerDataId);
  };
  const borderColor = theme.palette.mode === 'dark' ? 'white' : 'black';


  const renderGridItem = (item: any, index: number) => {
    if (!item || !item.answer) return null;
    return (
      <Box
        key={index}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding: '16px',
          margin: '8px',
          textAlign: 'left',
          width: 'calc(33.33% - 16px)',
          cursor: 'pointer',
          backgroundColor: fullScreenItem === item.answerDataId ? 'rgba(0, 123, 255, 0.1)' : 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(0, 123, 255, 0.1)',
          },
        }}
        onClick={() => handleChangeInFullScreen(`panel${index}`, item)}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Q: {item.answer.question}
        </Typography>
        <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary', mt: 2 }}>
          {new Date(item.answer.date).toLocaleDateString()} {new Date(item.answer.date).toLocaleTimeString()}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ margin: 'auto', mt: 4, width: '95%', padding: '20px', marginBlock: '3rem', marginLeft: '-0.5rem', paddingBottom: '100px' }}>
      <Box sx={{}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button variant="contained" onClick={handleMainSearchFullScreen} disabled={!data.answerData || data.answerData.length === 0 || !data.answerData[0]}>
            {currentView === 'search' ? 'Back to Video' : 'Go Full Screen'}
          </Button>
        </Box>
        {currentView === 'search' ? (
          <Grid container sx={{ justifyContent: 'center' }}>
            {data && data.answerData && data.answerData.map((item: any, index: any) => renderGridItem(item, index))}
          </Grid>
        ) : (
          data && data.answerData && sortedAnswerData.map((item: any, index: any) => {
            if (!item || !item.answer) {
              // Handle the null or undefined item appropriately
              return null; // Skip rendering this item
            }
            return (
              <Accordion ref={(el) => (accordionRefs.current[`panel${index}`] = el)} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`, item)} key={index} sx={{ background: colors[index % colors.length], color: theme.palette.text.primary, marginTop: '0.1rem', borderRadius: '5px', border: '1px solid', borderColor: borderColor }}>
                <AccordionSummary
                  sx={{
                    direction: isHebrew(item?.answer?.question) ? 'rtl' : 'ltr',
                    padding: '0 20px',
                    display: 'flex',
                    alignItems: 'center', // Align items vertically in the center
                    justifyContent: 'space-between' // Align content to the ends
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mb: '-8px' }}>
                    {/* Question text with ellipsis */}
                    <Tooltip title={item?.answer?.question} placement="top">
                      <Typography
                        sx={{
                          direction: isHebrew(item?.answer?.question) ? 'rtl' : 'ltr',
                          whiteSpace: 'normal', // Always unwrap text when accordion is expanded
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '100%', // Ensure the text stays within the container
                          mb: '-8px' // Move the text up by adjusting the margin-bottom
                        }}
                      >
                        {expanded === `panel${index}`
                          ? item?.answer?.question // Full text when expanded
                          : item?.answer?.question?.length > 40 // Truncate if text length exceeds 55 characters
                            ? `${item.answer.question.slice(0, 40)}...` // Show truncated text with ellipsis
                            : item?.answer?.question || ''} {/* Fallback to empty string if undefined */}
                      </Typography>
                    </Tooltip>
                  </Box>

                  {/* Container for the date and delete button */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {expanded === `panel${index}` && (
                      <React.Fragment>

                      </React.Fragment>
                    )}
                    {expanded !== `panel${index}` && (
                      <React.Fragment>
                        <Tooltip title="Delete this section" placement="top">
                          <IconButton onClick={() => setDeleteConfirmOpen(item.answerDataId)}>
                            <DeleteIcon sx={{ cursor: 'pointer', fontSize: '20px', color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={new Date(item?.answer?.date).toLocaleTimeString('en-GB')} placement="top">
                          <Typography
                            variant="caption"
                            sx={{
                              direction: isHebrew(item?.answer?.question) ? 'ltr' : 'rtl',
                              color: 'text.secondary',
                              mr: 1, // Margin to create space between the date and the delete icon
                            }}
                          >
                            {new Date(item?.answer?.date).toLocaleDateString()}
                          </Typography>
                        </Tooltip>
                      </React.Fragment>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {loadingStates[`panel${index}`] ? (
                    <Box sx={{ marginLeft: '160px' }}>
                      <LoadingDots message={"You answer for this question is coming"} />
                    </Box>
                  ) : (
                    <React.Fragment>
                      <Dialog
                        open={deleteConfirmOpen ? true : false}
                        onClose={() => setDeleteConfirmOpen(null)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        key={index}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Deletion"}
                        </DialogTitle>
                        <DialogContent key={index}>
                          <DialogContentText id="alert-dialog-description" key={index}>
                            Are you sure you want to Remove this section?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions key={index}>
                          <Button onClick={() => setDeleteConfirmOpen(null)}>Cancel</Button>
                          <Button key={index} onClick={handleMovieDelete} autoFocus>
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* Add numbering to Semantics and Indexes */}
                      <Tooltip title="Delete this section" placement="top">
                        <IconButton onClick={() => setDeleteConfirmOpen(item.answerDataId)}>
                          <DeleteIcon sx={{ cursor: 'pointer', fontSize: '30px', color: theme.palette.mode === 'dark' ? '#e0ebeb' : 'gray' }} />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        variant="caption"
                        sx={{
                          direction: isHebrew(item?.answer?.question) ? 'ltr' : 'rtl',
                          color: 'text.secondary',
                          ml: 2, // Space between delete icon and date
                          fontSize: '16px'
                        }}
                      >
                        {new Date(item?.answer?.date).toLocaleDateString()} {new Date(item?.answer?.date).toLocaleTimeString('en-GB')}
                      </Typography>
                      {item.movies && item.movies.map((movie: any, mIndex: number) => (
                        <React.Fragment key={mIndex}>
                          {movie.semantic && movie.semantic.map((semantic: Semantic, sIndex: number) => (
                            <React.Fragment key={sIndex}>
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', direction: isHebrew(item.answer.question) ? 'rtl' : 'ltr' }}>
                                  {isHebrew(item.answer.question) ? `תימוכין ${sIndex + 1}:` : `Reference ${sIndex + 1}:`}
                                </Typography>
                                <VideoSemantic
                                  key={sIndex}
                                  data={semantic}
                                  isOpen={false} // Since we are using accordion, isOpen is controlled differently                           
                                />
                              </Box>
                            </React.Fragment>
                          ))}
                          {movie.index && movie.index.map((indexItem: Index, iIndex: number) => (
                            <React.Fragment key={iIndex}>
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', direction: isHebrew(item.answer.question) ? 'rtl' : 'ltr' }}>
                                  {isHebrew(item.answer.question) ? `תשובה ${iIndex + 1}:` : `Answer ${iIndex + 1}:`}
                                </Typography>
                                <VideoIndex
                                  key={iIndex}
                                  question={item.answer.question}
                                  data={indexItem}
                                  isOpen={false} // Since we are using accordion, isOpen is controlled differently                         
                                />
                              </Box>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                      <Typography variant="h6" sx={{ fontWeight: 'bold', direction: isHebrew(item.answer.question) ? 'rtl' : 'ltr' }}>
                        {isHebrew(item.answer.question) ? `תשובה מקיפה:` : `Comprehensive answer:`}
                      </Typography>
                      {item.answer && item.answer.answer && item.answer.answer === "Waiting for an answer..." ?
                        <LoadingDots
                          isEnabled={true}
                          messages={[
                            "Let's first understand what you're asking",
                            "We're carefully processing the details",
                            "Now, we're diving deep to analyze your question",
                            "Checking the best possible sources for your answer",
                            "Ensuring accuracy as we gather data",
                            "Almost there, compiling everything for you",
                            "Hang tight, we're fetching exactly what you need",
                            "Your answer is just moments away"
                          ]}
                        />
                        :
                        <VideoAnswer item={item.answer} data={data} />}
                    </React.Fragment>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          }
          ))}

      </Box>
    </Box >
  )
}

export default MainSearch;
