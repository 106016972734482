

import React, { useState } from 'react';
import { Box, AppBar, Toolbar, Grid, useTheme, makeStyles } from '@material-ui/core';
import LogoComponent from './LogoComponent';
import DropDownList from './DropDownList';
import MainIconBar from './MainIconBar';
import ArchiveComponent from './ArchiveComponent';
import AvatarMenuComponent from '../../components/avatarMenuComponents/avatarMenuComponent';
interface TranscriptNavBarProps {
  setActiveComponent: (componentName: string) => void;
  archiveData: any;
  onArchiveItemClick: (sessionId: string) => void;
  showInteractIcon: boolean;
  selectedArchiveSessionId: string | null;
  selectedOption: string;
  setSelectedOption: any;
  items: any[];
  handleChangesInAddons: (selectedSession: string) => any;

}

const MainNavBar: React.FC<TranscriptNavBarProps> = ({ showInteractIcon, setActiveComponent, archiveData, onArchiveItemClick, selectedArchiveSessionId, selectedOption, setSelectedOption, items, handleChangesInAddons }) => {

  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);
  const classes = useStyles();
  const theme = useTheme();


  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Box className={classes.avatarMenu}>
        <AvatarMenuComponent userName='user demo' />
      </Box>
      <Toolbar >
        <Grid container direction="column" alignItems="flex-start" className={classes.gridContainer}>
          <Grid item className={classes.mainIconBarContainer}>
            <LogoComponent />
            <DropDownList selectedOption={selectedOption} setSelectedOption={setSelectedOption} items={items} handleChangesInAddons={handleChangesInAddons} />
            <MainIconBar showInteractIcon={showInteractIcon} setActiveComponent={setActiveComponent} setSelectedSessionId={setSelectedSessionId} selectedSessionId={selectedSessionId} />
          </Grid>
          {archiveData && (<ArchiveComponent selectedArchiveSessionId={selectedArchiveSessionId} archiveData={archiveData} onArchiveItemClick={onArchiveItemClick} setSelectedSessionId={setSelectedSessionId} selectedSessionId={selectedSessionId} />)}
          {!archiveData && (<Box className={classes.archiveEmpty}></Box>)}
        </Grid>
      </Toolbar>
    </AppBar>
  );

};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'black', // Black background
    width: '17.5%', // 13% of the page width
    height: '120vh', // Full height
    left: 0, // Positioned on the right
    top: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start', // Align content to the top
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Full width on small screens
      height: 'auto',
    },
  },
  avatarMenu: {
    zIndex: 1000,
  },
  gridContainer: {
    marginLeft: '-10px', // Add left margin
    paddingLeft: '-10px',
    marginTop: '210px',
    overflow: 'hidden', // Hide overflow
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center', // Center items on small screens
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start', // Adjust this for positioning
    width: '100%',
  },
  archiveEmpty: {
    marginTop: '112vh',

  },
  mainIconBarContainer: {
    // position: 'relative', // Position the container
    // top:'50px'
  }
}));

export default MainNavBar;