import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Button, CircularProgress, Container, Typography, Alert, Paper } from '@mui/material';
import * as yup from 'yup';
import { resetPassword } from '../../features/userSlice'; // You will need to implement this action in your userSlice.

interface FormValues {
  password: string;
  confirmPassword: string;
}

const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password should be at least 8 characters long')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
});

export const ResetPasswordForm: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Get the reset token from the URL

  if (!token) {
    return <Alert severity="error">Invalid or missing token!</Alert>;
  }

  const onSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      await dispatch(resetPassword({ token, newPassword: values.password })).unwrap();
      setSubmitting(false);
      navigate('/login'); // Redirect to login page after successful password reset
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Paper elevation={3} style={{ padding: '2em', width: '100%' }}>
          <Typography variant="h4" align="center">Reset Password</Typography>
          <Typography variant="subtitle2" align="center" style={{ marginBottom: '2em' }}>
            Enter your new password below.
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Field
                  component={TextField}
                  name="password"
                  type="password"
                  label="New Password"
                  fullWidth
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  fullWidth
                  margin="normal"
                />
                <Box display="flex" justifyContent="center" mt={2}>
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button variant="contained" color="primary" onClick={submitForm}>
                      Reset Password
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>
    </Container>
  );
};
